<template>
    <div id="ChangePassword" class="login-form-style">
        <ElForm
            :label-position="store.getters.labelPosition"
            label-width="260px"
            ref="passwordFormRef"
            :model="passwordFormData"
            :rules="passwordFormRules"
        >
            <ElFormItem
                class="f14 f14-mb"
                label="古いパスワード"
                prop="oldPassword"
            >
                <ElInput
                    v-model="passwordFormData.oldPassword"
                    type="password"
                ></ElInput>
            </ElFormItem>
            <ElFormItem
                class="f14 f14-mb"
                label="新しいパスワード"
                prop="password"
            >
                <ElInput
                    v-model="passwordFormData.password"
                    type="password"
                ></ElInput>
            </ElFormItem>
            <ElFormItem
                class="f14 f14-mb"
                label="新しいパスワード（確認用）"
                prop="passwordCheck"
            >
                <ElInput
                    v-model="passwordFormData.passwordCheck"
                    type="password"
                ></ElInput>
            </ElFormItem>
            <div class="button-wrap">
                <button class="f18 f16-mb" @click.prevent="submitPasswordForm">
                    パスワードを変更
                </button>
            </div>
        </ElForm>
    </div>
</template>
<script>
export default {
    name: 'ChangePassword',
}
</script>
<script setup>
import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { passwordRule, getPasswordCheckRule } from '@/utils/formRules'
import { updateUserPassword } from '@/service/api.js'
import { ElMessageBox } from 'element-plus'
const store = useStore()
const router = useRouter()

const passwordFormRef = ref(null)
const passwordFormData = reactive({
    oldPassword: '',
    password: '',
    passwordCheck: '',
})
const passwordFormRules = {
    oldPassword: passwordRule,
    password: passwordRule,
    passwordCheck: getPasswordCheckRule(passwordFormData),
}
function submitPasswordForm() {
    passwordFormRef.value.validate(async (valid) => {
        if (valid) {
            await updateUserPassword(
                passwordFormData.oldPassword,
                passwordFormData.password
            ).then(() => {
                ElMessageBox.alert(
                    'パスワードを変更しました。<br/>一旦ログアウトいたしますので、お手数ですが、新しいパスワードで再度ログインして下さい。',
                    'パスワードを変更しました',
                    {
                        confirmButtonText: '完了',
                        dangerouslyUseHTMLString: true,
                        callback: () => {
                            router.push({
                                name: 'MemberSystemLogin',
                            })
                        },
                    }
                )
            })
        }
    })
}
</script>
<style lang="scss" scoped>
@import '@/style/element.scss';
</style>
